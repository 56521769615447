import React from 'react';
import { X, Music2, Youtube, ExternalLink } from 'lucide-react';

interface MusicModalProps {
  onClose: () => void;
}

export default function MusicModal({ onClose }: MusicModalProps) {
  const services = [
    {
      name: 'Spotify',
      icon: Music2,
      color: 'from-green-500 to-green-600',
      connectUrl: 'https://accounts.spotify.com/authorize'
    },
    {
      name: 'YouTube Music',
      icon: Youtube,
      color: 'from-red-500 to-red-600',
      connectUrl: 'https://accounts.google.com/o/oauth2/v2/auth'
    }
  ];

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-md rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Connect Music Service</h3>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6 space-y-4">
          {services.map(({ name, icon: Icon, color, connectUrl }) => (
            <a
              key={name}
              href={connectUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`w-full bg-gradient-to-r ${color} rounded-lg p-[2px] block group`}
            >
              <div className="bg-black rounded-lg p-4 flex items-center gap-3 group-hover:bg-black/50 transition-colors">
                <Icon className="h-6 w-6 text-white" />
                <span className="text-white flex-1">Connect {name}</span>
                <ExternalLink className="h-5 w-5 text-white/60" />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}