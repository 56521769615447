import React from 'react';
import { FileText } from 'lucide-react';

export default function Documents() {
  return (
    <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <FileText className="h-6 w-6 text-pink-500" />
        <h1 className="text-2xl font-bold text-white">Documents</h1>
      </div>
      <p className="text-white/60">Document management coming soon...</p>
    </div>
  );
}