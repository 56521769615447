import type { AIMessage } from '../../types/chat';

const MAX_HISTORY = 10;

export class ConversationManager {
  private history: AIMessage[] = [];

  public addMessage(message: AIMessage): void {
    this.history.push(message);
    if (this.history.length > MAX_HISTORY) {
      this.history = this.history.slice(-MAX_HISTORY);
    }
  }

  public getHistory(): AIMessage[] {
    return [...this.history];
  }

  public clear(): void {
    this.history = [];
  }

  public getContextWindow(): AIMessage[] {
    return this.history.slice(-5);
  }
}