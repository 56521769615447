import React, { useState, useEffect } from 'react';
import { X, Search, ExternalLink, Loader2, AlertCircle } from 'lucide-react';
import { openAIClient } from '../services/openai';

interface ImageAnalysisModalProps {
  image: {
    file: File;
    preview: string;
  };
  onClose: () => void;
}

export default function ImageAnalysisModal({ image, onClose }: ImageAnalysisModalProps) {
  const [analysis, setAnalysis] = useState<string>('');
  const [isAnalyzing, setIsAnalyzing] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchUrl, setSearchUrl] = useState<string | null>(null);

  useEffect(() => {
    analyzeImage();
  }, []);

  const analyzeImage = async () => {
    try {
      setIsAnalyzing(true);
      setError(null);

      // Convert image to base64
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const base64Image = reader.result as string;
          
          // Get analysis from OpenAI
          const response = await openAIClient.analyzeImage(base64Image);
          setAnalysis(response);

          // Create Google search URL
          const searchQuery = encodeURIComponent(response.split('.')[0]);
          setSearchUrl(`https://www.google.com/search?q=${searchQuery}`);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to analyze image');
        } finally {
          setIsAnalyzing(false);
        }
      };

      reader.onerror = () => {
        setError('Failed to read image file');
        setIsAnalyzing(false);
      };

      reader.readAsDataURL(image.file);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to analyze image');
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-2xl rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Search className="h-5 w-5 text-pink-500" />
            <h3 className="text-xl font-bold text-white">Image Analysis</h3>
          </div>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6 space-y-6">
          <div className="aspect-video rounded-lg overflow-hidden bg-black/50">
            <img
              src={image.preview}
              alt="Uploaded image"
              className="w-full h-full object-contain"
            />
          </div>

          {error ? (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 flex items-center gap-3">
              <AlertCircle className="h-6 w-6 text-red-500 flex-shrink-0" />
              <p className="text-red-500">{error}</p>
            </div>
          ) : isAnalyzing ? (
            <div className="flex items-center justify-center gap-3 text-white/60 p-4">
              <Loader2 className="h-6 w-6 animate-spin" />
              <span>Analyzing image...</span>
            </div>
          ) : (
            <>
              <div className="bg-white/5 rounded-lg p-4">
                <h4 className="text-white font-medium mb-2">Analysis Result:</h4>
                <p className="text-white/80">{analysis}</p>
              </div>

              {searchUrl && (
                <a
                  href={searchUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 bg-gradient-to-r from-pink-500 to-yellow-400 rounded-lg p-3 text-white font-medium hover:opacity-90 transition-opacity"
                >
                  <span>Search on Google</span>
                  <ExternalLink className="h-5 w-5" />
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}