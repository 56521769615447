import React, { useState } from 'react';
import { X, Mail, ExternalLink, AlertCircle, Loader2 } from 'lucide-react';
import { OAuth2Client } from '../../services/oauth2';
import { useTheme } from '../../contexts/ThemeContext';

interface EmailModalProps {
  onClose: () => void;
}

export default function EmailModal({ onClose }: EmailModalProps) {
  const { currentTheme } = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectingProvider, setConnectingProvider] = useState<string | null>(null);

  const services = [
    {
      name: 'Gmail',
      icon: Mail,
      color: 'from-red-500 to-red-600',
      provider: 'google' as const,
      description: 'Connect your Gmail account to manage emails and drafts'
    },
    {
      name: 'Outlook',
      icon: Mail,
      color: 'from-blue-500 to-blue-600',
      provider: 'microsoft' as const,
      description: 'Connect your Outlook account to manage emails and calendar'
    }
  ];

  const handleConnect = async (provider: 'google' | 'microsoft') => {
    setError(null);
    setIsConnecting(true);
    setConnectingProvider(provider);

    try {
      const client = new OAuth2Client(provider);
      await client.authorize();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to connect');
      setIsConnecting(false);
      setConnectingProvider(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-md rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Connect Email Service</h3>
          <button 
            onClick={onClose}
            className="text-white/60 hover:text-white"
            disabled={isConnecting}
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6 space-y-4">
          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
              <p className="text-red-500 text-sm">{error}</p>
            </div>
          )}

          {services.map(({ name, icon: Icon, color, provider, description }) => (
            <button
              key={name}
              onClick={() => handleConnect(provider)}
              disabled={isConnecting}
              className={`w-full bg-gradient-to-r ${color} rounded-lg p-[2px] block group
                ${isConnecting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <div className={`${currentTheme.cardStyle} rounded-lg p-4 space-y-2`}>
                <div className="flex items-center gap-3">
                  <Icon className="h-6 w-6 text-white" />
                  <span className="text-white flex-1 text-left">
                    {connectingProvider === provider ? 'Connecting...' : `Connect ${name}`}
                  </span>
                  {connectingProvider === provider ? (
                    <Loader2 className="h-5 w-5 text-white/60 animate-spin" />
                  ) : (
                    <ExternalLink className="h-5 w-5 text-white/60" />
                  )}
                </div>
                <p className="text-sm text-white/60 text-left">{description}</p>
              </div>
            </button>
          ))}

          <p className="text-sm text-white/60 text-center mt-4">
            By connecting a service, you agree to our{' '}
            <a href="/terms" className="text-pink-500 hover:underline">Terms of Service</a>
            {' '}and{' '}
            <a href="/privacy" className="text-pink-500 hover:underline">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
}