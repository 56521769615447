import React, { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { OAuth2Client } from '../../services/oauth2';
import ErrorMessage from './ErrorMessage';

interface GoogleServiceConnectProps {
  service: 'calendar' | 'sheets' | 'tasks';
  icon: React.ElementType;
  title: string;
  description: string;
}

export default function GoogleServiceConnect({ 
  service, 
  icon: Icon, 
  title, 
  description 
}: GoogleServiceConnectProps) {
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleConnect = async () => {
    setError(null);
    setIsConnecting(true);

    try {
      const client = new OAuth2Client('google');
      await client.authorize();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to connect to Google service');
      setIsConnecting(false);
    }
  };

  return (
    <div className="space-y-4">
      <button
        onClick={handleConnect}
        disabled={isConnecting}
        className="w-full bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg p-[2px] block group disabled:opacity-50"
      >
        <div className="bg-black rounded-lg p-4 space-y-2 group-hover:bg-black/50 transition-colors">
          <div className="flex items-center gap-3">
            <Icon className="h-6 w-6 text-white" />
            <span className="text-white flex-1 text-left">
              {isConnecting ? 'Connecting...' : `Connect ${title}`}
            </span>
            {isConnecting && (
              <Loader2 className="h-5 w-5 text-white/60 animate-spin" />
            )}
          </div>
          <p className="text-sm text-white/60 text-left">{description}</p>
        </div>
      </button>

      {error && (
        <ErrorMessage 
          message="Connection failed" 
          details="Please ensure you have granted the necessary permissions and try again."
        />
      )}
    </div>
  );
}