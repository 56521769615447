interface OpenAIConfig {
  apiKey: string;
  apiUrl: string;
  defaultModel: string;
  defaultParams: {
    temperature: number;
    max_tokens: number;
    presence_penalty: number;
    frequency_penalty: number;
    top_p: number;
  };
}

export const openaiConfig: OpenAIConfig = {
  apiKey: import.meta.env.VITE_OPENAI_API_KEY || '',
  apiUrl: import.meta.env.VITE_OPENAI_API_URL || 'https://api.openai.com/v1',
  defaultModel: import.meta.env.VITE_OPENAI_MODEL || 'gpt-3.5-turbo',
  defaultParams: {
    temperature: parseFloat(import.meta.env.VITE_OPENAI_TEMPERATURE || '0.7'),
    max_tokens: parseInt(import.meta.env.VITE_OPENAI_MAX_TOKENS || '150', 10),
    presence_penalty: parseFloat(import.meta.env.VITE_OPENAI_PRESENCE_PENALTY || '0.6'),
    frequency_penalty: parseFloat(import.meta.env.VITE_OPENAI_FREQUENCY_PENALTY || '0.5'),
    top_p: parseFloat(import.meta.env.VITE_OPENAI_TOP_P || '0.9')
  }
};