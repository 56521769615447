import React, { useState, useRef } from 'react';
import { Sparkles, MessageSquare, Search, Music, Calendar, Settings, AlertCircle, Loader2 } from 'lucide-react';
import { useAISecretary } from '../contexts/AISecretaryContext';
import { openAIClient } from '../services/openai';
import Chat from './Chat';
import MusicModal from './services/MusicModal';
import ScheduleModal from './services/ScheduleModal';
import ProfileCustomization from './ProfileCustomization';
import ImageAnalysisModal from './ImageAnalysisModal';

export default function AIAssistant() {
  const { profile } = useAISecretary();
  const [showChat, setShowChat] = useState(false);
  const [showMusicModal, setShowMusicModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showProfileCustomization, setShowProfileCustomization] = useState(false);
  const [showImageAnalysis, setShowImageAnalysis] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<{ file: File; preview: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setUploadError(null);
    setSelectedImage(null);

    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setUploadError('Please upload an image file');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('Image size should be less than 5MB');
      return;
    }

    try {
      setIsLoading(true);
      const previewUrl = URL.createObjectURL(file);
      setSelectedImage({ file, preview: previewUrl });
      setShowImageAnalysis(true);
    } catch (error) {
      setUploadError('Failed to process image. Please try again.');
    } finally {
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <div className="relative w-full max-w-md">
        <div className="rounded-2xl bg-gradient-to-r from-pink-500 to-yellow-400 p-[2px]">
          <div className="rounded-2xl bg-black p-6 backdrop-blur-xl">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-16 h-16 rounded-full bg-gradient-to-r from-pink-500 to-yellow-400 p-[2px] relative group">
                <img
                  src={profile.image}
                  alt={profile.name}
                  className="w-full h-full object-cover rounded-full"
                />
                <button
                  onClick={() => setShowProfileCustomization(true)}
                  className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <Settings className="h-6 w-6 text-white" />
                </button>
              </div>
              <div>
                <h2 className="text-xl font-bold text-white">{profile.name}</h2>
                <p className="text-pink-500">Your AI Secretary</p>
              </div>
              <Sparkles className="ml-auto h-6 w-6 text-yellow-400" />
            </div>
            
            <div className="space-y-4">
              <button 
                onClick={() => setShowChat(true)}
                className="w-full bg-gradient-to-r from-pink-500 to-yellow-400 rounded-lg p-[2px] group"
              >
                <div className="bg-black rounded-lg p-3 flex items-center gap-3 group-hover:bg-black/50 transition-colors">
                  <MessageSquare className="h-5 w-5 text-pink-500" />
                  <span className="text-white">Start Chat</span>
                </div>
              </button>
              
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden"
                aria-label="Upload image for analysis"
              />
              
              <button 
                onClick={() => fileInputRef.current?.click()}
                disabled={isLoading}
                className="w-full bg-white/10 rounded-lg p-3 flex items-center gap-3 hover:bg-white/20 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? (
                  <Loader2 className="h-5 w-5 text-yellow-400 animate-spin" />
                ) : (
                  <Search className="h-5 w-5 text-yellow-400" />
                )}
                <span className="text-white flex-1">
                  {isLoading ? 'Processing...' : 'Analyze Image with AI'}
                </span>
              </button>

              {uploadError && (
                <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
                  <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                  <p className="text-red-500 text-sm">{uploadError}</p>
                </div>
              )}
              
              <div className="grid grid-cols-2 gap-4">
                <button 
                  onClick={() => setShowMusicModal(true)}
                  className="bg-white/10 rounded-lg p-3 flex items-center gap-2 hover:bg-white/20 transition-colors"
                >
                  <Music className="h-5 w-5 text-pink-500" />
                  <span className="text-white text-sm">Music</span>
                </button>
                <button 
                  onClick={() => setShowScheduleModal(true)}
                  className="bg-white/10 rounded-lg p-3 flex items-center gap-2 hover:bg-white/20 transition-colors"
                >
                  <Calendar className="h-5 w-5 text-yellow-400" />
                  <span className="text-white text-sm">Schedule</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="absolute -bottom-4 left-1/2 -translate-x-1/2 w-32 h-1 bg-gradient-to-r from-transparent via-pink-500 to-transparent blur-sm" />
      </div>

      {showChat && <Chat onClose={() => setShowChat(false)} />}
      {showMusicModal && <MusicModal onClose={() => setShowMusicModal(false)} />}
      {showScheduleModal && <ScheduleModal onClose={() => setShowScheduleModal(false)} />}
      {showProfileCustomization && (
        <ProfileCustomization onClose={() => setShowProfileCustomization(false)} />
      )}
      {showImageAnalysis && selectedImage && (
        <ImageAnalysisModal
          image={selectedImage}
          onClose={() => {
            setShowImageAnalysis(false);
            setSelectedImage(null);
            URL.revokeObjectURL(selectedImage.preview);
          }}
        />
      )}
    </>
  );
}