import OpenAI from 'openai';
import { openaiConfig } from './config';
import { handleOpenAIError } from './errors';
import { createSystemPrompt } from './prompts';
import { ConversationManager } from './conversation';
import type { AIMessage } from '../../types/chat';

class OpenAIClient {
  private client: OpenAI | null = null;
  private static instance: OpenAIClient;
  private demoMode: boolean = false;
  private conversationManager: ConversationManager;

  private constructor() {
    this.conversationManager = new ConversationManager();
    this.initializeClient();
  }

  private initializeClient(): void {
    if (!openaiConfig.apiKey || !openaiConfig.apiKey.startsWith('sk-')) {
      this.demoMode = true;
      return;
    }

    try {
      this.client = new OpenAI({
        apiKey: openaiConfig.apiKey,
        baseURL: openaiConfig.apiUrl,
        dangerouslyAllowBrowser: true
      });
      this.demoMode = false;
    } catch (error) {
      console.error('Failed to initialize OpenAI client:', error);
      this.demoMode = true;
    }
  }

  public static getInstance(): OpenAIClient {
    if (!OpenAIClient.instance) {
      OpenAIClient.instance = new OpenAIClient();
    }
    return OpenAIClient.instance;
  }

  private getDemoResponse(): string {
    const responses = [
      "I'm in demo mode. To enable AI features, please configure a valid OpenAI API key.",
      "This is a demo response. For full functionality, add your OpenAI API key in the settings.",
      "Demo mode active. I can show you the interface, but AI responses require API configuration.",
      "To access AI features, please set up your OpenAI API key in the settings panel."
    ];
    return responses[Math.floor(Math.random() * responses.length)];
  }

  public async analyzeImage(base64Image: string): Promise<string> {
    if (this.demoMode || !this.client) {
      return "This is a demo analysis. To get real AI-powered image analysis, please configure your OpenAI API key.";
    }

    try {
      const response = await this.client.chat.completions.create({
        model: "gpt-4-vision-preview",
        messages: [
          {
            role: "system",
            content: "You are an expert at analyzing images and providing detailed, accurate descriptions. Focus on the main subjects and important details. Keep descriptions concise but informative."
          },
          {
            role: "user",
            content: [
              { type: "text", text: "What's in this image? Provide a concise description that would be good for a web search." },
              {
                type: "image_url",
                image_url: base64Image
              }
            ]
          }
        ],
        max_tokens: 150
      });

      return response.choices[0].message.content || 
        "I apologize, but I couldn't analyze this image.";
    } catch (error) {
      throw handleOpenAIError(error);
    }
  }

  public async createChatCompletion(
    messages: AIMessage[],
    personality: string
  ): Promise<string> {
    if (this.demoMode || !this.client) {
      const response = this.getDemoResponse();
      this.conversationManager.addMessage({ text: response, isUser: false });
      return response;
    }

    try {
      const contextWindow = this.conversationManager.getContextWindow();
      
      const completion = await this.client.chat.completions.create({
        model: openaiConfig.defaultModel,
        messages: [
          { role: "system", content: createSystemPrompt(personality) },
          ...contextWindow.map(msg => ({
            role: msg.isUser ? "user" : "assistant",
            content: msg.text
          }))
        ],
        temperature: openaiConfig.defaultParams.temperature,
        max_tokens: openaiConfig.defaultParams.max_tokens,
        presence_penalty: openaiConfig.defaultParams.presence_penalty,
        frequency_penalty: openaiConfig.defaultParams.frequency_penalty,
        top_p: openaiConfig.defaultParams.top_p
      });

      const response = completion.choices[0].message.content || 
        "I apologize, but I couldn't process that request.";
      
      this.conversationManager.addMessage({ text: response, isUser: false });
      return response;
    } catch (error) {
      throw handleOpenAIError(error);
    }
  }

  public isDemoMode(): boolean {
    return this.demoMode;
  }

  public clearConversation(): void {
    this.conversationManager.clear();
  }

  public reinitialize(apiKey: string): void {
    openaiConfig.apiKey = apiKey;
    this.initializeClient();
  }
}

export const openAIClient = OpenAIClient.getInstance();