import React from 'react';
import { X, Bell } from 'lucide-react';

interface NotificationsPanelProps {
  onClose: () => void;
}

export default function NotificationsPanel({ onClose }: NotificationsPanelProps) {
  const notifications = [
    {
      id: 1,
      title: 'New Email',
      message: 'You have a new email from John Doe',
      time: '5 minutes ago'
    },
    {
      id: 2,
      title: 'Meeting Reminder',
      message: 'Team meeting starts in 15 minutes',
      time: '10 minutes ago'
    }
  ];

  return (
    <div className="absolute right-0 top-16 mt-2 w-96 bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-xl shadow-xl z-50 mr-4">
      <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Bell className="h-5 w-5 text-pink-500" />
          <h3 className="text-lg font-semibold text-white">Notifications</h3>
        </div>
        <button onClick={onClose} className="text-white/60 hover:text-white">
          <X className="h-5 w-5" />
        </button>
      </div>
      
      <div className="max-h-[400px] overflow-y-auto">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="p-4 border-b border-pink-500/10 hover:bg-white/5 transition-colors"
          >
            <h4 className="text-white font-medium">{notification.title}</h4>
            <p className="text-white/60 text-sm mt-1">{notification.message}</p>
            <span className="text-pink-500 text-xs mt-2 block">{notification.time}</span>
          </div>
        ))}
      </div>
    </div>
  );
}