import React from 'react';
import { X, Calendar } from 'lucide-react';
import GoogleServiceConnect from './GoogleServiceConnect';

interface ScheduleModalProps {
  onClose: () => void;
}

export default function ScheduleModal({ onClose }: ScheduleModalProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-md rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Connect Calendar Service</h3>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6 space-y-6">
          <GoogleServiceConnect
            service="calendar"
            icon={Calendar}
            title="Google Calendar"
            description="Connect your Google Calendar to manage events and meetings"
          />
          
          <p className="text-sm text-white/60 text-center">
            By connecting a service, you agree to our{' '}
            <a href="/terms" className="text-pink-500 hover:underline">Terms of Service</a>
            {' '}and{' '}
            <a href="/privacy" className="text-pink-500 hover:underline">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
}