import React, { createContext, useContext, useState } from 'react';

interface AISecretaryProfile {
  name: string;
  image: string;
}

interface AISecretaryContextType {
  profile: AISecretaryProfile;
  updateProfile: (newProfile: Partial<AISecretaryProfile>) => void;
  availableProfiles: Array<{ name: string; image: string }>;
}

const defaultProfiles = [
  { name: 'Nova', image: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e?auto=format&fit=crop&w=300' },
  { name: 'Quantum', image: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&fit=crop&w=300' },
  { name: 'Echo', image: 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?auto=format&fit=crop&w=300' },
  { name: 'Cypher', image: 'https://images.unsplash.com/photo-1534723452862-4c874018d66d?auto=format&fit=crop&w=300' },
  { name: 'Iris-7', image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?auto=format&fit=crop&w=300' },
  { name: 'Neo-X', image: 'https://images.unsplash.com/photo-1546776310-eef45dd6d63c?auto=format&fit=crop&w=300' },
  { name: 'Cortex', image: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?auto=format&fit=crop&w=300' },
  { name: 'Zenith', image: 'https://images.unsplash.com/photo-1535378917042-10a22c95931a?auto=format&fit=crop&w=300' }
];

const AISecretaryContext = createContext<AISecretaryContextType | undefined>(undefined);

export function AISecretaryProvider({ children }: { children: React.ReactNode }) {
  const [profile, setProfile] = useState<AISecretaryProfile>({
    name: 'Nova',
    image: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e?auto=format&fit=crop&w=300'
  });

  const updateProfile = (newProfile: Partial<AISecretaryProfile>) => {
    setProfile(prev => ({ ...prev, ...newProfile }));
  };

  return (
    <AISecretaryContext.Provider value={{ 
      profile,
      updateProfile,
      availableProfiles: defaultProfiles
    }}>
      {children}
    </AISecretaryContext.Provider>
  );
}

export function useAISecretary() {
  const context = useContext(AISecretaryContext);
  if (context === undefined) {
    throw new Error('useAISecretary must be used within an AISecretaryProvider');
  }
  return context;
}