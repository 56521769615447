import React from 'react';
import { X, Settings } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

interface SettingsPanelProps {
  onClose: () => void;
}

export default function SettingsPanel({ onClose }: SettingsPanelProps) {
  const { currentTheme } = useTheme();

  return (
    <div className="absolute right-0 top-16 mt-2 w-96 bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-xl shadow-xl z-50 mr-4">
      <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Settings className={`h-5 w-5 text-${currentTheme.secondary}`} />
          <h3 className="text-lg font-semibold text-white">Settings</h3>
        </div>
        <button onClick={onClose} className="text-white/60 hover:text-white">
          <X className="h-5 w-5" />
        </button>
      </div>
      
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <label className="text-white font-medium">Notifications</label>
          <div className="flex items-center justify-between">
            <span className="text-white/60">Email notifications</span>
            <input type="checkbox" className="toggle" defaultChecked />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-white/60">Sound alerts</span>
            <input type="checkbox" className="toggle" />
          </div>
        </div>
        
        <div className="space-y-2">
          <label className="text-white font-medium">Privacy</label>
          <div className="flex items-center justify-between">
            <span className="text-white/60">Show online status</span>
            <input type="checkbox" className="toggle" defaultChecked />
          </div>
        </div>
      </div>
    </div>
  );
}