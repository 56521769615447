import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X, Home, Calendar, FileText, Settings, HelpCircle, Info } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

interface SideMenuProps {
  onClose: () => void;
}

export default function SideMenu({ onClose }: SideMenuProps) {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  
  const menuItems = [
    { icon: Home, label: 'Dashboard', path: '/' },
    { icon: Calendar, label: 'Calendar', path: '/calendar' },
    { icon: FileText, label: 'Documents', path: '/documents' },
    { icon: Settings, label: 'Settings', path: '/settings' },
    { icon: Info, label: 'About', path: '/about' },
    { icon: HelpCircle, label: 'Help', path: '/help' }
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex">
      <div className="w-64 bg-black/90 backdrop-blur-sm border-r border-pink-500/20 h-full">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <h3 className={`text-xl font-bold bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text`}>
            Menu
          </h3>
          <button 
            onClick={onClose} 
            className="text-white/60 hover:text-white"
            aria-label="Close menu"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <nav className="p-4">
          {menuItems.map(({ icon: Icon, label, path }) => (
            <button
              key={label}
              onClick={() => handleNavigation(path)}
              className="w-full p-3 rounded-lg text-white hover:bg-white/10 transition-colors flex items-center gap-3 mb-2"
            >
              <Icon className={`h-5 w-5 text-${currentTheme.secondary}`} />
              {label}
            </button>
          ))}

          <div className="border-t border-pink-500/20 mt-4 pt-4">
            <button
              onClick={() => handleNavigation('/privacy')}
              className="w-full p-3 rounded-lg text-white/60 hover:bg-white/10 transition-colors text-sm text-left"
            >
              Privacy Policy
            </button>
            <button
              onClick={() => handleNavigation('/terms')}
              className="w-full p-3 rounded-lg text-white/60 hover:bg-white/10 transition-colors text-sm text-left"
            >
              Terms of Service
            </button>
          </div>
        </nav>
      </div>
      
      <div 
        className="flex-1 bg-black/50" 
        onClick={onClose}
        aria-hidden="true"
      />
    </div>
  );
}