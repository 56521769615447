export class OpenAIServiceError extends Error {
  constructor(
    message: string,
    public code: string,
    public status?: number
  ) {
    super(message);
    this.name = 'OpenAIServiceError';
  }
}

export const handleOpenAIError = (error: any): OpenAIServiceError => {
  console.error('OpenAI API Error:', error);

  const status = error?.status;
  const code = error?.error?.code || 'unknown_error';
  const originalMessage = error?.error?.message;

  switch (code) {
    case 'insufficient_quota':
      return new OpenAIServiceError(
        'Your API quota has been exceeded. Please check your OpenAI account billing settings.',
        code,
        status
      );
    case 'rate_limit_exceeded':
      return new OpenAIServiceError(
        'Too many requests. Please wait a moment before trying again.',
        code,
        status
      );
    case 'invalid_api_key':
      return new OpenAIServiceError(
        'Invalid API key. Please check your OpenAI API key configuration.',
        code,
        status
      );
    case 'invalid_request_error':
      return new OpenAIServiceError(
        'Invalid request parameters. Please try again.',
        code,
        status
      );
    default:
      return new OpenAIServiceError(
        originalMessage || 'An unexpected error occurred. Please try again later.',
        code,
        status
      );
  }
};