import React from 'react';
import { Mail, FileText, DollarSign, Share2 } from 'lucide-react';
import EmailModal from './services/EmailModal';
import SocialModal from './services/SocialModal';
import TaskModal from './services/TaskModal';
import BudgetModal from './services/BudgetModal';

export default function Dashboard() {
  const [activeModal, setActiveModal] = React.useState<string | null>(null);

  const stats = [
    { 
      id: 'email',
      icon: Mail, 
      label: 'Emails', 
      value: '12', 
      color: 'text-pink-500'
    },
    { 
      id: 'tasks',
      icon: FileText, 
      label: 'Tasks', 
      value: '5', 
      color: 'text-yellow-400'
    },
    { 
      id: 'budget',
      icon: DollarSign, 
      label: 'Budget', 
      value: '$2.4k', 
      color: 'text-pink-500'
    },
    { 
      id: 'social',
      icon: Share2, 
      label: 'Posts', 
      value: '3', 
      color: 'text-yellow-400'
    },
  ];

  const handleClick = (id: string) => {
    setActiveModal(id);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  const renderModal = () => {
    switch (activeModal) {
      case 'email':
        return <EmailModal onClose={handleClose} />;
      case 'tasks':
        return <TaskModal onClose={handleClose} />;
      case 'budget':
        return <BudgetModal onClose={handleClose} />;
      case 'social':
        return <SocialModal onClose={handleClose} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {stats.map(({ id, icon: Icon, label, value, color }) => (
          <button
            key={id}
            onClick={() => handleClick(id)}
            className="bg-black/50 backdrop-blur-sm border border-white/10 rounded-xl p-4 w-full text-left hover:bg-black/70 transition-colors group"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-white/60 text-sm group-hover:text-white/80 transition-colors">{label}</p>
                <p className="text-2xl font-bold text-white mt-1">{value}</p>
              </div>
              <Icon className={`h-8 w-8 ${color} group-hover:scale-110 transition-transform`} />
            </div>
          </button>
        ))}
      </div>

      {renderModal()}
    </>
  );
}