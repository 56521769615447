import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ErrorMessageProps {
  message: string;
  details?: string;
}

export default function ErrorMessage({ message, details }: ErrorMessageProps) {
  return (
    <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
      <div className="flex items-center gap-3 mb-2">
        <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
        <p className="text-red-500 font-medium">{message}</p>
      </div>
      {details && (
        <p className="text-red-400/80 text-sm ml-8">{details}</p>
      )}
    </div>
  );
}