import type { AuthResponse } from '../types/auth';

interface OAuth2Config {
  clientId: string;
  authEndpoint: string;
  tokenEndpoint: string;
  redirectUri: string;
  scope: string[];
}

export class OAuth2Client {
  private config: OAuth2Config;
  private provider: string;

  constructor(provider: 'google' | 'microsoft') {
    this.provider = provider;
    this.config = this.getConfig(provider);
  }

  private getConfig(provider: string): OAuth2Config {
    const isProduction = window.location.hostname !== 'localhost';
    const baseUrl = isProduction 
      ? window.location.origin
      : 'http://localhost:3000';

    const configs: Record<string, OAuth2Config> = {
      google: {
        clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        authEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
        tokenEndpoint: 'https://oauth2.googleapis.com/token',
        redirectUri: `${baseUrl}/auth/callback/google`,
        scope: [
          'openid',
          'profile',
          'email',
          'https://www.googleapis.com/auth/gmail.readonly',
          'https://www.googleapis.com/auth/calendar',
          'https://www.googleapis.com/auth/tasks',
          'https://www.googleapis.com/auth/drive.readonly',
          'https://www.googleapis.com/auth/spreadsheets.readonly'
        ]
      },
      microsoft: {
        clientId: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
        authEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        tokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
        redirectUri: `${baseUrl}/auth/callback/microsoft`,
        scope: [
          'openid',
          'profile',
          'email',
          'Mail.Read',
          'Calendars.Read',
          'Tasks.Read',
          'Files.Read'
        ]
      }
    };

    const config = configs[provider];
    if (!config) {
      throw new Error(`Unsupported provider: ${provider}`);
    }
    if (!config.clientId) {
      throw new Error(`${provider} client ID not configured`);
    }

    return config;
  }

  public async authorize(): Promise<void> {
    const state = this.generateState();
    const nonce = this.generateNonce();
    
    localStorage.setItem('oauth_state', state);
    localStorage.setItem('oauth_nonce', nonce);
    localStorage.setItem('oauth_provider', this.provider);

    const params = new URLSearchParams({
      client_id: this.config.clientId,
      redirect_uri: this.config.redirectUri,
      scope: this.config.scope.join(' '),
      state,
      nonce,
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      include_granted_scopes: 'true'
    });

    window.location.href = `${this.config.authEndpoint}?${params}`;
  }

  private generateState(): string {
    return Array.from(crypto.getRandomValues(new Uint8Array(32)))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }

  private generateNonce(): string {
    return Array.from(crypto.getRandomValues(new Uint8Array(16)))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }

  public validateState(receivedState: string): boolean {
    const savedState = localStorage.getItem('oauth_state');
    localStorage.removeItem('oauth_state');
    return savedState === receivedState;
  }

  public async handleCallback(code: string): Promise<AuthResponse> {
    const formData = new URLSearchParams({
      grant_type: 'authorization_code',
      code,
      client_id: this.config.clientId,
      redirect_uri: this.config.redirectUri,
      client_secret: this.provider === 'google' 
        ? import.meta.env.VITE_GOOGLE_CLIENT_SECRET 
        : import.meta.env.VITE_MICROSOFT_CLIENT_SECRET
    });

    try {
      const response = await fetch(this.config.tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error_description || 'Token exchange failed');
      }

      const data = await response.json();
      return {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in
      };
    } catch (error) {
      console.error('Token exchange failed:', error);
      throw new Error('Authentication failed. Please try again.');
    }
  }
}