import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, User, AlertCircle } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import APIKeySetup from '../components/APIKeySetup';

export default function Register() {
  const navigate = useNavigate();
  const { currentTheme } = useTheme();
  const { setAuthCredentials } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAPIKeySetup, setShowAPIKeySetup] = useState(false);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      // Validation
      if (password.length < 8) {
        throw new Error('Password must be at least 8 characters long');
      }
      if (!acceptTerms) {
        throw new Error('You must accept the Terms of Service and Privacy Policy');
      }

      // Set auth credentials with email provider
      setAuthCredentials('email', {
        token: 'dummy-token',
        expiresIn: 3600
      });

      // Show API key setup after successful registration
      setShowAPIKeySetup(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAPIKeyComplete = (apiKey: string) => {
    navigate('/');
  };

  const handleAPIKeySkip = () => {
    navigate('/');
  };

  if (showAPIKeySetup) {
    return (
      <APIKeySetup
        onComplete={handleAPIKeyComplete}
        onSkip={handleAPIKeySkip}
      />
    );
  }

  return (
    <div className={`min-h-screen bg-gradient-to-br ${currentTheme.background} flex items-center justify-center p-4`}>
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop')] opacity-10 mix-blend-overlay" />
      
      <div className="w-full max-w-md">
        <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-8">
          <h2 className={`text-2xl font-bold text-center mb-8 bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text`}>
            Create your account
          </h2>

          <form onSubmit={handleRegister} className="space-y-6">
            <div>
              <label className="block text-white/60 text-sm font-medium mb-2">
                Full Name
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                  placeholder="Enter your name"
                  required
                />
                <User className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
              </div>
            </div>

            <div>
              <label className="block text-white/60 text-sm font-medium mb-2">
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                  placeholder="Enter your email"
                  required
                />
                <Mail className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
              </div>
            </div>

            <div>
              <label className="block text-white/60 text-sm font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                  placeholder="Create a password (min. 8 characters)"
                  required
                  minLength={8}
                />
                <Lock className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                  className="w-4 h-4 border border-white/10 rounded bg-white/10 focus:ring-pink-500"
                />
              </div>
              <div className="ml-3">
                <label htmlFor="terms" className="text-sm text-white/60">
                  I accept the{' '}
                  <Link to="/terms" className="text-pink-500 hover:underline">Terms of Service</Link>
                  {' '}and{' '}
                  <Link to="/privacy" className="text-pink-500 hover:underline">Privacy Policy</Link>
                </label>
              </div>
            </div>

            {error && (
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
                <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
                <p className="text-red-500 text-sm">{error}</p>
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading || !acceptTerms}
              className={`w-full bg-gradient-to-r ${currentTheme.primary} rounded-lg p-3 text-white font-medium 
                hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {isLoading ? 'Creating account...' : 'Create account'}
            </button>
          </form>

          <p className="mt-6 text-center text-white/60 text-sm">
            Already have an account?{' '}
            <button
              onClick={() => navigate('/login')}
              className={`text-${currentTheme.secondary} hover:underline`}
            >
              Sign in
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}