import React from 'react';
import { X } from 'lucide-react';
import { useAISecretary } from '../contexts/AISecretaryContext';

interface ProfileCustomizationProps {
  onClose: () => void;
}

export default function ProfileCustomization({ onClose }: ProfileCustomizationProps) {
  const { profile, updateProfile, availableProfiles } = useAISecretary();

  const handleSelectProfile = (name: string, image: string) => {
    updateProfile({ name, image });
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-4xl rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Choose Your AI Assistant</h3>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {availableProfiles.map((p) => (
              <button
                key={p.name}
                onClick={() => handleSelectProfile(p.name, p.image)}
                className={`group relative ${
                  profile.name === p.name ? 'ring-2 ring-pink-500' : ''
                }`}
              >
                <div className="aspect-square rounded-lg overflow-hidden">
                  <img
                    src={p.image}
                    alt={p.name}
                    className="w-full h-full object-cover transition-transform group-hover:scale-110"
                  />
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/0 to-transparent flex items-end p-2">
                  <span className="text-white text-sm">{p.name}</span>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}