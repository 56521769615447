import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTheme } from './contexts/ThemeContext';
import { useAuth } from './contexts/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import AIAssistant from './components/AIAssistant';
import Login from './routes/Login';
import Register from './routes/Register';
import AuthCallback from './routes/AuthCallback';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsOfService from './routes/TermsOfService';
import Calendar from './routes/Calendar';
import Documents from './routes/Documents';
import Settings from './routes/Settings';
import About from './routes/About';
import Help from './routes/Help';

export default function App() {
  const { currentTheme } = useTheme();
  const { isAuthenticated, isInitialized } = useAuth();

  // For development, temporarily bypass authentication
  const bypassAuth = true;
  
  if (!isInitialized) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-pink-500"></div>
      </div>
    );
  }

  if (!isAuthenticated && !bypassAuth) {
    return (
      <div className={`min-h-screen bg-black ${currentTheme.fontFamily}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/callback/:provider" element={<AuthCallback />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className={`min-h-screen flex flex-col bg-black ${currentTheme.fontFamily} relative overflow-hidden`}>
      <div 
        className={`absolute inset-0 opacity-10 mix-blend-overlay pointer-events-none bg-cover bg-center bg-no-repeat transition-opacity duration-1000`}
        style={{ backgroundImage: currentTheme.backgroundImage }}
        aria-hidden="true"
      />
      
      <div className={`absolute inset-0 bg-gradient-to-br ${currentTheme.background} opacity-80 pointer-events-none transition-opacity duration-1000`} />
      
      <Header />
      <main className="flex-1 pt-20 px-4 sm:px-6 lg:px-8 relative">
        <div className="max-w-7xl mx-auto">
          <Routes>
            <Route path="/" element={
              <>
                <Dashboard />
                <div className="flex flex-col items-center justify-center py-12">
                  <AIAssistant />
                </div>
              </>
            } />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/about" element={<About />} />
            <Route path="/help" element={<Help />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/auth/callback/:provider" element={<AuthCallback />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </div>
  );
}