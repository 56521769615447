import React, { createContext, useContext, useState } from 'react';

type Theme = {
  name: string;
  primary: string;
  secondary: string;
  accent: string;
  background: string;
  backgroundImage: string;
  fontFamily: string;
  borderStyle: string;
  buttonStyle: string;
  cardStyle: string;
  glowEffect: string;
};

const themes: Record<string, Theme> = {
  neon: {
    name: 'Neon Night',
    primary: 'from-pink-500 to-yellow-400',
    secondary: 'pink-500',
    accent: 'yellow-400',
    background: 'from-black via-black to-pink-900',
    backgroundImage: "url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1920')",
    fontFamily: 'font-sans',
    borderStyle: 'border-pink-500/20',
    buttonStyle: 'hover:shadow-pink-500/20 hover:shadow-lg',
    cardStyle: 'backdrop-blur-xl bg-black/90',
    glowEffect: 'after:absolute after:inset-0 after:bg-gradient-to-r after:from-pink-500/20 after:to-yellow-400/20 after:blur-xl after:-z-10'
  },
  cyber: {
    name: 'Cyber Blue',
    primary: 'from-cyan-400 to-blue-500',
    secondary: 'cyan-400',
    accent: 'blue-500',
    background: 'from-black via-black to-blue-900',
    backgroundImage: "url('https://images.unsplash.com/photo-1635776062127-d379bfcba9f4?auto=format&fit=crop&w=1920')",
    fontFamily: 'font-mono',
    borderStyle: 'border-cyan-500/20',
    buttonStyle: 'hover:shadow-cyan-500/20 hover:shadow-lg',
    cardStyle: 'backdrop-blur-xl bg-black/80',
    glowEffect: 'after:absolute after:inset-0 after:bg-gradient-to-r after:from-cyan-500/20 after:to-blue-500/20 after:blur-xl after:-z-10'
  },
  matrix: {
    name: 'Matrix Green',
    primary: 'from-green-400 to-emerald-600',
    secondary: 'green-400',
    accent: 'emerald-600',
    background: 'from-black via-black to-green-900',
    backgroundImage: "url('https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?auto=format&fit=crop&w=1920')",
    fontFamily: 'font-mono',
    borderStyle: 'border-green-500/20',
    buttonStyle: 'hover:shadow-green-500/20 hover:shadow-lg',
    cardStyle: 'backdrop-blur-xl bg-black/70',
    glowEffect: 'after:absolute after:inset-0 after:bg-gradient-to-r after:from-green-500/20 after:to-emerald-500/20 after:blur-xl after:-z-10'
  },
  synthwave: {
    name: 'Synthwave',
    primary: 'from-purple-500 to-pink-500',
    secondary: 'purple-500',
    accent: 'pink-500',
    background: 'from-black via-purple-900/20 to-pink-900',
    backgroundImage: "url('https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&fit=crop&w=1920')",
    fontFamily: 'font-sans',
    borderStyle: 'border-purple-500/20',
    buttonStyle: 'hover:shadow-purple-500/20 hover:shadow-lg',
    cardStyle: 'backdrop-blur-xl bg-black/60',
    glowEffect: 'after:absolute after:inset-0 after:bg-gradient-to-r after:from-purple-500/20 after:to-pink-500/20 after:blur-xl after:-z-10'
  },
  quantum: {
    name: 'Quantum Realm',
    primary: 'from-indigo-400 to-violet-500',
    secondary: 'indigo-400',
    accent: 'violet-500',
    background: 'from-black via-indigo-900/20 to-violet-900',
    backgroundImage: "url('https://images.unsplash.com/photo-1464802686167-b939a6910659?auto=format&fit=crop&w=1920')",
    fontFamily: 'font-sans',
    borderStyle: 'border-indigo-500/20',
    buttonStyle: 'hover:shadow-indigo-500/20 hover:shadow-lg',
    cardStyle: 'backdrop-blur-xl bg-black/75',
    glowEffect: 'after:absolute after:inset-0 after:bg-gradient-to-r after:from-indigo-500/20 after:to-violet-500/20 after:blur-xl after:-z-10'
  }
};

type ThemeContextType = {
  currentTheme: Theme;
  setTheme: (themeName: string) => void;
  themes: Record<string, Theme>;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [currentTheme, setCurrentTheme] = useState<Theme>(themes.neon);

  const setTheme = (themeName: string) => {
    const theme = themes[themeName];
    if (theme) {
      setCurrentTheme(theme);
      // Update body classes
      document.body.className = `${theme.fontFamily} bg-black`;
      // Update background image
      document.body.style.backgroundImage = theme.backgroundImage;
    }
  };

  return (
    <ThemeContext.Provider value={{ currentTheme, setTheme, themes }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}