import React from 'react';
import { X, User } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

interface ProfilePanelProps {
  onClose: () => void;
}

export default function ProfilePanel({ onClose }: ProfilePanelProps) {
  const { currentTheme } = useTheme();

  return (
    <div className="absolute right-0 top-16 mt-2 w-96 bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-xl shadow-xl z-50 mr-4">
      <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <User className={`h-5 w-5 text-${currentTheme.secondary}`} />
          <h3 className="text-lg font-semibold text-white">Profile</h3>
        </div>
        <button onClick={onClose} className="text-white/60 hover:text-white">
          <X className="h-5 w-5" />
        </button>
      </div>
      
      <div className="p-4">
        <div className="flex items-center gap-4 mb-6">
          <div className={`w-16 h-16 rounded-full bg-gradient-to-r ${currentTheme.primary} p-[2px]`}>
            <div className="w-full h-full rounded-full bg-black flex items-center justify-center">
              <User className="h-8 w-8 text-white" />
            </div>
          </div>
          <div>
            <h4 className="text-white font-medium">Guest User</h4>
            <p className="text-white/60">AI Assistant Demo</p>
          </div>
        </div>
      </div>
    </div>
  );
}