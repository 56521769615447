import React, { useState, useEffect } from 'react';
import { Bot, Mail, Calendar, CheckSquare, Brain } from 'lucide-react';

export default function PreviewVideo() {
  const [currentFeature, setCurrentFeature] = useState(0);

  const features = [
    {
      icon: Mail,
      title: "Smart Email Management",
      description: "AI-powered email sorting and response drafting",
      animation: "translate-y-0 opacity-100"
    },
    {
      icon: Calendar,
      title: "Intelligent Scheduling",
      description: "Automatically finds the best meeting times",
      animation: "translate-x-0 opacity-100"
    },
    {
      icon: CheckSquare,
      title: "Task Automation",
      description: "Handles repetitive tasks and follow-ups",
      animation: "scale-100 opacity-100"
    },
    {
      icon: Brain,
      title: "AI-Powered Assistance",
      description: "Your personal AI secretary, available 24/7",
      animation: "rotate-0 opacity-100"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-8">
      <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-8">
        <div className="flex flex-col items-center text-center mb-8">
          <Bot className="h-12 w-12 text-pink-500 mb-4" />
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-yellow-400 mb-4">
            Your AI Secretary
          </h2>
          <p className="text-white/60 max-w-2xl">
            Experience the future of personal assistance with AI-powered automation
          </p>
        </div>

        <div className="relative h-[400px] rounded-xl overflow-hidden bg-gradient-to-br from-pink-500/10 to-yellow-400/10">
          {/* Feature Showcase */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center p-8 transition-all duration-500 ease-in-out">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div
                    key={index}
                    className={`absolute inset-0 flex flex-col items-center justify-center transition-all duration-500 transform
                      ${index === currentFeature ? feature.animation : 'opacity-0 translate-y-full'}`}
                  >
                    <Icon className="h-24 w-24 text-pink-500 mb-6 animate-pulse" />
                    <h3 className="text-2xl font-bold text-white mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-white/60 max-w-md">
                      {feature.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Progress Indicators */}
          <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {features.map((_, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-300
                  ${index === currentFeature ? 'bg-pink-500 w-4' : 'bg-white/20'}`}
              />
            ))}
          </div>
        </div>

        {/* Feature List */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <button
                key={index}
                onClick={() => setCurrentFeature(index)}
                className={`p-4 rounded-lg transition-all duration-300 ${
                  index === currentFeature
                    ? 'bg-pink-500/20 border border-pink-500/40'
                    : 'bg-white/5 hover:bg-white/10'
                }`}
              >
                <Icon className={`h-6 w-6 mb-2 ${
                  index === currentFeature ? 'text-pink-500' : 'text-white/60'
                }`} />
                <p className="text-sm text-white font-medium">
                  {feature.title}
                </p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}