import React, { useState, useEffect } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { useAISecretary } from '../contexts/AISecretaryContext';
import ChatMessage from './chat/ChatMessage';
import ChatInput from './chat/ChatInput';
import ChatError from './chat/ChatError';
import { openAIClient, type AIMessage } from '../services/openai';

interface ChatProps {
  onClose: () => void;
}

export default function Chat({ onClose }: ChatProps) {
  const { profile } = useAISecretary();
  const [message, setMessage] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(openAIClient.isDemoMode());
  const [messages, setMessages] = useState<AIMessage[]>([]);

  useEffect(() => {
    setMessages([{ 
      text: isDemoMode 
        ? "I'm in demo mode. While AI features are limited, I can still help you explore the interface and features."
        : `Hello! I'm ${profile.name}, your AI secretary. How can I help you today?`,
      isUser: false 
    }]);
  }, [profile.name, isDemoMode]);

  const handleSend = async () => {
    if (!message.trim() || isLoading) return;

    setError(null);
    setIsLoading(true);
    const userMessage = message.trim();
    setMessage('');

    setMessages(prev => [...prev, { text: userMessage, isUser: true }]);
    
    try {
      const response = await openAIClient.createChatCompletion(
        [...messages, { text: userMessage, isUser: true }],
        profile.personality || 'professional'
      );
      
      setMessages(prev => [...prev, { text: response, isUser: false }]);
      setIsDemoMode(openAIClient.isDemoMode());
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to process your request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-black/90 w-full max-w-2xl rounded-2xl border border-pink-500/20">
        <div className="p-4 border-b border-pink-500/20 flex justify-between items-center">
          <div className="flex items-center gap-3">
            <img
              src={profile.image}
              alt={profile.name}
              className="w-8 h-8 rounded-full object-cover"
            />
            <h3 className="text-xl font-bold text-white">Chat with {profile.name}</h3>
          </div>
          <button onClick={onClose} className="text-white/60 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>

        {isDemoMode && (
          <div className="p-4 bg-yellow-500/10 border-b border-yellow-500/20">
            <div className="flex items-center gap-2 text-yellow-500">
              <AlertCircle className="h-5 w-5 flex-shrink-0" />
              <p className="text-sm">
                Demo mode active. AI features are limited. To enable full functionality, please provide a valid OpenAI API key.
              </p>
            </div>
          </div>
        )}
        
        <div className="h-[400px] overflow-y-auto p-4 space-y-4">
          {messages.map((msg, idx) => (
            <ChatMessage key={idx} {...msg} />
          ))}
        </div>
        
        <div className="p-4 border-t border-pink-500/20 space-y-4">
          {error && <ChatError message={error} />}
          <ChatInput
            message={message}
            onChange={setMessage}
            onSend={handleSend}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}