import React from 'react';
import { Info } from 'lucide-react';

export default function About() {
  return (
    <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <Info className="h-6 w-6 text-pink-500" />
        <h1 className="text-2xl font-bold text-white">About Zellofy</h1>
      </div>
      
      <div className="space-y-6 text-white/80">
        <section>
          <h2 className="text-xl font-semibold text-white mb-3">Our Mission</h2>
          <p>
            Zellofy aims to revolutionize personal productivity through AI-powered assistance,
            making digital life simpler and more efficient for everyone.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-white mb-3">What We Do</h2>
          <p>
            We provide an intelligent AI secretary that helps manage your emails, calendar,
            tasks, and documents, allowing you to focus on what matters most.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-white mb-3">Our Technology</h2>
          <p>
            Powered by cutting-edge AI and machine learning, Zellofy learns from your
            preferences and habits to provide personalized assistance.
          </p>
        </section>
      </div>
    </div>
  );
}