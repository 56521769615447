import React, { useState } from 'react';
import { Menu, Bell, User, Settings, Zap } from 'lucide-react';
import ThemeSwitcher from './ThemeSwitcher';
import { useTheme } from '../contexts/ThemeContext';
import NotificationsPanel from './navigation/NotificationsPanel';
import SettingsPanel from './navigation/SettingsPanel';
import ProfilePanel from './navigation/ProfilePanel';
import SideMenu from './navigation/SideMenu';

export default function Header() {
  const { currentTheme } = useTheme();
  const [activePanel, setActivePanel] = useState<string | null>(null);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const togglePanel = (panelName: string) => {
    setActivePanel(activePanel === panelName ? null : panelName);
  };

  return (
    <header className="bg-black/90 backdrop-blur-sm border-b border-pink-500/20 fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center gap-4">
            <button
              onClick={() => setShowSideMenu(true)}
              className="p-2 rounded-lg hover:bg-white/10 transition-colors"
            >
              <Menu className={`h-6 w-6 text-${currentTheme.secondary}`} />
            </button>
            <div className="flex items-center gap-2">
              <Zap className="h-8 w-8 text-pink-500" />
              <span className={`text-2xl font-bold bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text`}>
                Zellofy
              </span>
            </div>
          </div>
          
          <div className="flex items-center gap-4">
            <ThemeSwitcher />
            
            <button
              onClick={() => togglePanel('notifications')}
              className="p-2 rounded-lg hover:bg-white/10 transition-colors relative"
            >
              <Bell className={`h-6 w-6 text-${currentTheme.secondary} hover:text-${currentTheme.accent} transition-colors`} />
              <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
            </button>
            
            <button
              onClick={() => togglePanel('settings')}
              className="p-2 rounded-lg hover:bg-white/10 transition-colors"
            >
              <Settings className={`h-6 w-6 text-${currentTheme.secondary} hover:text-${currentTheme.accent} transition-colors`} />
            </button>
            
            <button
              onClick={() => togglePanel('profile')}
              className={`w-8 h-8 rounded-full bg-gradient-to-r ${currentTheme.primary} p-[2px] hover:scale-110 transition-transform`}
            >
              <div className="w-full h-full rounded-full bg-black flex items-center justify-center">
                <User className="h-5 w-5 text-white" />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Navigation Panels */}
      {activePanel === 'notifications' && <NotificationsPanel onClose={() => setActivePanel(null)} />}
      {activePanel === 'settings' && <SettingsPanel onClose={() => setActivePanel(null)} />}
      {activePanel === 'profile' && <ProfilePanel onClose={() => setActivePanel(null)} />}
      {showSideMenu && <SideMenu onClose={() => setShowSideMenu(false)} />}
    </header>
  );
}