import React, { useState } from 'react';
import { Key, AlertCircle, ExternalLink } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';

interface APIKeySetupProps {
  onComplete: (apiKey: string) => void;
  onSkip: () => void;
}

export default function APIKeySetup({ onComplete, onSkip }: APIKeySetupProps) {
  const { currentTheme } = useTheme();
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      // Validate API key format
      if (!apiKey.startsWith('sk-') || apiKey.length < 20) {
        throw new Error('Invalid API key format');
      }

      // Store API key securely
      localStorage.setItem('OPENAI_API_KEY', apiKey);
      onComplete(apiKey);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save API key');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="w-full max-w-md bg-black/90 rounded-2xl border border-pink-500/20 p-6">
        <div className="text-center mb-6">
          <h2 className={`text-2xl font-bold bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text mb-2`}>
            Set Up Your AI Secretary
          </h2>
          <p className="text-white/60">
            To enable AI features, please provide your OpenAI API key
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-white/60 text-sm font-medium mb-2">
              OpenAI API Key
            </label>
            <div className="relative">
              <input
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                placeholder="sk-..."
              />
              <Key className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
            </div>
          </div>

          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
              <p className="text-red-500 text-sm">{error}</p>
            </div>
          )}

          <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-3">
            <p className="text-sm text-white/80 mb-2">
              Don't have an API key?
            </p>
            <a
              href="https://platform.openai.com/account/api-keys"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1"
            >
              Get one from OpenAI <ExternalLink className="h-4 w-4" />
            </a>
          </div>

          <div className="flex gap-3">
            <button
              type="submit"
              disabled={isLoading}
              className={`flex-1 bg-gradient-to-r ${currentTheme.primary} rounded-lg p-3 text-white font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              {isLoading ? 'Saving...' : 'Save API Key'}
            </button>
            <button
              type="button"
              onClick={onSkip}
              className="flex-1 bg-white/10 rounded-lg p-3 text-white font-medium hover:bg-white/20 transition-colors"
            >
              Skip for now
            </button>
          </div>
        </form>

        <p className="mt-4 text-center text-white/60 text-sm">
          Your API key is stored securely in your browser and is never sent to our servers
        </p>
      </div>
    </div>
  );
}