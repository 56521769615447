import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OAuth2Client } from '../services/oauth2';
import { useAuth } from '../contexts/AuthContext';
import { Loader2, AlertCircle } from 'lucide-react';
import APIKeySetup from '../components/APIKeySetup';

export default function AuthCallback() {
  const [error, setError] = useState<string | null>(null);
  const [showAPIKeySetup, setShowAPIKeySetup] = useState(false);
  const navigate = useNavigate();
  const { provider } = useParams<{ provider: string }>();
  const { setAuthCredentials } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const state = params.get('state');
      const error = params.get('error');
      const errorDescription = params.get('error_description');

      if (error) {
        setError(errorDescription || 'Authentication failed');
        return;
      }

      if (!code || !state || !provider) {
        setError('Invalid authentication response');
        return;
      }

      try {
        const client = new OAuth2Client(provider as 'google' | 'microsoft');
        
        if (!client.validateState(state)) {
          setError('Invalid state parameter');
          return;
        }

        const credentials = await client.handleCallback(code);
        setAuthCredentials(provider as 'google' | 'microsoft', credentials);
        setShowAPIKeySetup(true);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Authentication failed');
      }
    };

    handleCallback();
  }, [navigate, provider, setAuthCredentials]);

  const handleAPIKeyComplete = (apiKey: string) => {
    navigate('/', { replace: true });
  };

  const handleAPIKeySkip = () => {
    navigate('/', { replace: true });
  };

  if (showAPIKeySetup) {
    return (
      <APIKeySetup
        onComplete={handleAPIKeyComplete}
        onSkip={handleAPIKeySkip}
      />
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-black">
        <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-6 max-w-md w-full">
          <div className="flex items-center gap-3 mb-4">
            <AlertCircle className="h-6 w-6 text-red-500" />
            <h2 className="text-red-500 font-bold">Authentication Error</h2>
          </div>
          <p className="text-white/60 mb-6">{error}</p>
          <button
            onClick={() => navigate('/login')}
            className="w-full bg-white/10 text-white rounded-lg p-3 hover:bg-white/20 transition-colors"
          >
            Return to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="flex items-center gap-3 text-white">
        <Loader2 className="h-6 w-6 animate-spin" />
        <span>Completing authentication...</span>
      </div>
    </div>
  );
}