import React from 'react';
import { HelpCircle } from 'lucide-react';

export default function Help() {
  return (
    <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <HelpCircle className="h-6 w-6 text-pink-500" />
        <h1 className="text-2xl font-bold text-white">Help Center</h1>
      </div>
      
      <div className="space-y-6 text-white/80">
        <section>
          <h2 className="text-xl font-semibold text-white mb-3">Getting Started</h2>
          <p>
            Learn how to set up your AI secretary and make the most of Zellofy's features.
            Documentation coming soon...
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-white mb-3">FAQs</h2>
          <p>
            Find answers to commonly asked questions about our service.
            FAQ section coming soon...
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-white mb-3">Contact Support</h2>
          <p>
            Need help? Contact our support team at{' '}
            <a href="mailto:support@zellofy.com" className="text-pink-500 hover:underline">
              support@zellofy.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}