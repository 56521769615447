import React from 'react';
import { Send } from 'lucide-react';

interface ChatInputProps {
  message: string;
  onChange: (value: string) => void;
  onSend: () => void;
  isLoading: boolean;
}

export default function ChatInput({ 
  message, 
  onChange, 
  onSend, 
  isLoading 
}: ChatInputProps) {
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <div className="flex gap-2">
      <input
        type="text"
        value={message}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message..."
        className="flex-1 bg-white/10 rounded-lg px-4 py-2 text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-pink-500"
        disabled={isLoading}
      />
      <button
        onClick={onSend}
        disabled={isLoading || !message.trim()}
        className="bg-gradient-to-r from-pink-500 to-yellow-400 rounded-lg p-2 text-white hover:opacity-90 transition-opacity disabled:opacity-50"
      >
        <Send className="h-5 w-5" />
      </button>
    </div>
  );
}