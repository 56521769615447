import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

export default function TermsOfService() {
  const navigate = useNavigate();
  const { currentTheme } = useTheme();

  return (
    <div className="min-h-screen bg-black text-white p-4 sm:p-6 lg:p-8">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-white/60 hover:text-white mb-8"
        >
          <ArrowLeft className="h-5 w-5" />
          Back
        </button>

        <div className="bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-6 sm:p-8">
          <h1 className={`text-3xl font-bold bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text mb-6`}>
            Terms of Service
          </h1>

          <div className="space-y-6 text-white/80">
            <section>
              <h2 className="text-xl font-semibold text-white mb-3">1. Acceptance of Terms</h2>
              <p>
                By accessing or using Zellofy's services, you agree to be bound by these Terms of Service. 
                If you disagree with any part of the terms, you may not access the service.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">2. Account Registration</h2>
              <p>To use our services, you must:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Create an account with accurate information</li>
                <li>Maintain the security of your account credentials</li>
                <li>Be at least 18 years old</li>
                <li>Accept our Privacy Policy</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">3. Service Usage</h2>
              <p>You agree not to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Use the service for any illegal purposes</li>
                <li>Violate any laws in your jurisdiction</li>
                <li>Share inappropriate or harmful content</li>
                <li>Attempt to gain unauthorized access</li>
                <li>Interfere with the service's operation</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">4. Intellectual Property</h2>
              <p>
                The service and its original content, features, and functionality are owned by Zellofy 
                and are protected by international copyright, trademark, patent, trade secret, 
                and other intellectual property laws.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">5. Third-Party Services</h2>
              <p>
                Our service integrates with third-party services. Your use of these services is subject 
                to their respective terms and conditions.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">6. Termination</h2>
              <p>
                We may terminate or suspend your account immediately, without prior notice or liability, 
                for any reason, including breach of these Terms.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">7. Limitation of Liability</h2>
              <p>
                In no event shall Zellofy be liable for any indirect, incidental, special, consequential, 
                or punitive damages arising out of or relating to your use of the service.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">8. Changes to Terms</h2>
              <p>
                We reserve the right to modify or replace these Terms at any time. We will provide 
                notice of any changes by posting the new Terms on this page.
              </p>
              <p className="mt-4 text-white/60">Last Updated: {new Date().toLocaleDateString()}</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-3">9. Contact Us</h2>
              <p>
                If you have any questions about these Terms, please contact us at{' '}
                <a href="mailto:legal@zellofy.com" className="text-pink-500 hover:underline">
                  legal@zellofy.com
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}