import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock, Chrome, Mail as OutlookIcon } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { OAuth2Client } from '../services/oauth2';
import PreviewVideo from '../components/PreviewVideo';

export default function Login() {
  const navigate = useNavigate();
  const { currentTheme } = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/');
    } catch (err) {
      setError('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOAuthLogin = async (provider: 'google' | 'microsoft') => {
    try {
      const client = new OAuth2Client(provider);
      await client.authorize();
    } catch (err) {
      setError('Authentication failed. Please try again.');
    }
  };

  return (
    <div className={`min-h-screen bg-gradient-to-br ${currentTheme.background} p-4`}>
      <div className="max-w-7xl mx-auto pt-8">
        <PreviewVideo />
        
        <div className="mt-12 flex justify-center">
          <div className="w-full max-w-md bg-black/90 backdrop-blur-sm border border-pink-500/20 rounded-2xl p-8">
            <h2 className={`text-2xl font-bold text-center mb-8 bg-gradient-to-r ${currentTheme.primary} text-transparent bg-clip-text`}>
              Welcome Back
            </h2>

            <div className="space-y-4">
              <button
                onClick={() => handleOAuthLogin('google')}
                className="w-full bg-white/10 hover:bg-white/20 transition-colors rounded-lg p-3 text-white flex items-center justify-center gap-2"
              >
                <Chrome className="h-5 w-5" />
                Continue with Google
              </button>

              <button
                onClick={() => handleOAuthLogin('microsoft')}
                className="w-full bg-white/10 hover:bg-white/20 transition-colors rounded-lg p-3 text-white flex items-center justify-center gap-2"
              >
                <OutlookIcon className="h-5 w-5" />
                Continue with Outlook
              </button>

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-white/10"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-black text-white/60">Or</span>
                </div>
              </div>

              <form onSubmit={handleEmailLogin} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                    placeholder="Email"
                    required
                  />
                  <Mail className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
                </div>

                <div className="relative">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full bg-white/10 border border-white/10 rounded-lg px-4 py-2 pl-10 text-white placeholder-white/40 focus:outline-none focus:border-pink-500/50"
                    placeholder="Password"
                    required
                  />
                  <Lock className="absolute left-3 top-2.5 h-5 w-5 text-white/40" />
                </div>

                {error && (
                  <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 text-red-500 text-sm">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full bg-gradient-to-r ${currentTheme.primary} rounded-lg p-3 text-white font-medium hover:opacity-90 transition-opacity disabled:opacity-50`}
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}