import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ChatErrorProps {
  message: string;
}

export default function ChatError({ message }: ChatErrorProps) {
  return (
    <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
      <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0" />
      <p className="text-red-500 text-sm">{message}</p>
    </div>
  );
}