import React from 'react';

interface ChatMessageProps {
  text: string;
  isUser: boolean;
}

export default function ChatMessage({ text, isUser }: ChatMessageProps) {
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
      <div className={`max-w-[80%] rounded-lg p-3 ${
        isUser 
          ? 'bg-gradient-to-r from-pink-500 to-yellow-400 text-white'
          : 'bg-white/10 text-white'
      }`}>
        {text}
      </div>
    </div>
  );
}