import React, { createContext, useContext, useState } from 'react';

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | {
    id: string;
    email: string;
    name: string;
  };
}

interface AuthContextType extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string, name: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    isAuthenticated: false,
    isInitialized: true,
    user: null
  });

  const login = async (email: string, password: string) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setState({
      isAuthenticated: true,
      isInitialized: true,
      user: {
        id: '1',
        email,
        name: 'Demo User'
      }
    });
  };

  const logout = () => {
    setState({
      isAuthenticated: false,
      isInitialized: true,
      user: null
    });
  };

  const register = async (email: string, password: string, name: string) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setState({
      isAuthenticated: true,
      isInitialized: true,
      user: {
        id: '1',
        email,
        name
      }
    });
  };

  return (
    <AuthContext.Provider value={{
      ...state,
      login,
      logout,
      register
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}